var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import FileUpload from "~/components/common/file-upload.vue";
import { Dependencies } from "~/core/decorator";
import { Emit, Prop } from "vue-property-decorator";
// import { PrincipalService } from "~/services/business-service/principal.service";
import { TemplateDataService } from "~/services/management-service/template-data.service";
import { DistributeCaseService } from "~/services/domain-service/distribute-case.service";
import { ImportOthersDataExcelRecordService } from "~/services/dataimp-service/import-others-data-excel-record.service";
var AnnexImportConfig = /** @class */ (function (_super) {
    __extends(AnnexImportConfig, _super);
    function AnnexImportConfig() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.importModel = {
            fileId: "",
            principalId: "",
            principalName: "",
            templateId: "",
            // batchNumber: "", // 批次号
            templateType: _this.importType //导入类型
        };
        _this.importRules = {
            principalId: [{ required: true, message: "请选择委托方", trigger: "blur" }],
            templateId: [
                { required: true, message: "请选择导入模板", trigger: "blur" }
            ]
        };
        _this.caseIssuedFlag = "";
        _this.batchNumberList = [];
        _this.fileList = [];
        _this.importLoading = false;
        _this.principalList = []; //委托方列表
        _this.templateList = []; //模板列表
        _this.loading = {
            state: false
        };
        return _this;
    }
    AnnexImportConfig.prototype.close = function () {
        this.reset();
    };
    AnnexImportConfig.prototype.success = function () {
        this.close();
    };
    AnnexImportConfig.prototype.created = function () { };
    AnnexImportConfig.prototype.mounted = function () {
        // 获取委托方列表
        // this.principalService
        //   .findAll()
        //   .subscribe(data => (this.principalList = data));
        var _this = this;
        // 批次号
        this.distributeCaseService
            .getBatchNumberByCaseIssuedFlag(this.caseIssuedFlag)
            .subscribe(function (data) { return (_this.batchNumberList = data); });
    };
    /**
     * 表单重置
     */
    AnnexImportConfig.prototype.reset = function () {
        this.templateList = [];
        this.importLoading = false;
        var form = this.$refs["form"];
        var upload = this.$refs["upload_case_import"];
        form.resetFields();
        upload.reset();
    };
    /**
     * 取消
     */
    AnnexImportConfig.prototype.cancel = function () {
        this.close();
    };
    /**
     * 表单提交
     */
    AnnexImportConfig.prototype.submit = function () {
        var _this = this;
        if (this.fileList.length === 0) {
            this.$message("请上传导入文件");
            return;
        }
        // 文件ID
        this.importModel.fileId = this.fileList[0].response.id;
        var form = this.$refs["form"];
        form.validate(function (valid) {
            if (!valid)
                return;
            _this.importLoading = true;
            _this.importOthersDataExcelRecordService
                .importOthersExcelData(_this.importModel)
                .subscribe(function (data) {
                _this.$message.success("补充材料导入成功");
                _this.success();
                _this.importLoading = false;
            }, function (err) {
                _this.$message.error(err.msg);
                _this.importLoading = false;
            });
        });
    };
    /**
     * 委托方ID变化
     */
    AnnexImportConfig.prototype.principalIdChange = function () {
        var _this = this;
        //根据委托方ID查找委托方Obj并且获取模板名称
        var principalObj = this.principalList.find(function (v) { return v.id === _this.importModel.principalId; });
        this.importModel.principalName = principalObj.principalName;
        //重置模板列表并获取选取委托方对应的模板列表
        this.templateList = [];
        this.importModel.templateId = "";
        var excelModel = {
            principalId: this.importModel.principalId,
            templateType: this.importType
        };
        this.loading.state = true;
        // this.templateDataService
        //   .getTemplateDataSelect(excelModel, this.loading)
        //   .subscribe(data => (this.templateList = data));
    };
    __decorate([
        Dependencies(TemplateDataService)
    ], AnnexImportConfig.prototype, "templateDataService", void 0);
    __decorate([
        Dependencies(DistributeCaseService)
    ], AnnexImportConfig.prototype, "distributeCaseService", void 0);
    __decorate([
        Dependencies(ImportOthersDataExcelRecordService)
    ], AnnexImportConfig.prototype, "importOthersDataExcelRecordService", void 0);
    __decorate([
        Prop({ required: true })
    ], AnnexImportConfig.prototype, "importType", void 0);
    __decorate([
        Emit()
    ], AnnexImportConfig.prototype, "close", null);
    __decorate([
        Emit()
    ], AnnexImportConfig.prototype, "success", null);
    AnnexImportConfig = __decorate([
        Component({
            components: {
                FileUpload: FileUpload
            }
        })
    ], AnnexImportConfig);
    return AnnexImportConfig;
}(Vue));
export default AnnexImportConfig;
